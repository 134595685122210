/* 
 * Mobile Viewport Fixes
 * This file contains CSS fixes for mobile viewport issues, particularly on iOS devices.
 */
#app-container {
  /* Default padding for all devices */
  padding-top: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
  padding-left: env(safe-area-inset-left, 0);
  padding-right: env(safe-area-inset-right, 0);

  box-sizing: border-box;
  position: relative;
  min-height: -webkit-fill-available;
}

@supports (-webkit-touch-callout: none) {
  #app-container {
    padding-top: 0;
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom, 0));
  }
  @media screen and (orientation: landscape) {
    #app-container {
      padding-top: 0;
    }
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  #app-container {
    padding-top: 0;
    padding-bottom: constant(safe-area-inset-bottom, 0);
    padding-left: constant(safe-area-inset-left, 0);
    padding-right: constant(safe-area-inset-right, 0);
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  #app-container {
    padding-top: 0;
  }
}

.ios-device.keyboard-visible #app-container {
  height: calc(var(--vh, 1vh) * 100 - 260px);
}
